import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';


export const fetchCarData = createAsyncThunk(
  'car/fetchCarData',
  async ({ vehicleId, userId }) => {
    const payload = {
      vehicle_id: vehicleId,
    };

    if (userId) {
      payload.user_id = userId;
    }

    const response = await axios.post('/listcar-individual', payload);
    return response?.data?.individual;
  }
);



const IndividualCarSlice = createSlice({
  name: 'car',
  initialState: {
    carData: null,
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCarData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCarData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.carData = action.payload;
      })
      .addCase(fetchCarData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  }
});

export default IndividualCarSlice.reducer;
