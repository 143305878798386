import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  city: 'Delhi',
  city_id: 94,
};

const locationSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    setCity: (state, action) => {
      state.city = action.payload.cityName;
      state.city_id = action.payload.cityId;
    },
  },
});

export const { setCity } = locationSlice.actions;
export default locationSlice.reducer;