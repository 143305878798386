import { combineReducers } from '@reduxjs/toolkit';
import locationReducer from './Location';
import locationModalReducer from './LocationModal';
import AuthReducer from './AuthSlice';
import IndividualCarReducer from './IndividualCarSlice';
import UserReducer from './UserSlice';

const rootReducer = combineReducers({
  location: locationReducer,
  modal: locationModalReducer,
  auth: AuthReducer,
  car: IndividualCarReducer,
  user: UserReducer,
});


export default rootReducer;