import { createSlice } from '@reduxjs/toolkit';

const UserSlice = createSlice({
  name: 'user',
  initialState: {
    name: '',
    email: '',
    phone: '',
    likedCars: [],
  },
  reducers: {
    setUserInfo(state, action) {
      const { name, email, phone } = action.payload;
      state.name = name;
      state.email = email;
      state.phone = phone;
    },
    toggleLikedCar(state, action) {
      const vehicle_id = action.payload;
      if (state.likedCars.includes(vehicle_id)) {
        // Remove car ID if it's already liked
        state.likedCars = state.likedCars.filter(id => id !== vehicle_id);
      } else {
        // Add car ID to liked cars if not liked yet
        state.likedCars.push(vehicle_id);
      }
    },
  },
});

export const { setUserInfo, toggleLikedCar } = UserSlice.actions;

export default UserSlice.reducer;
