import { createSlice } from '@reduxjs/toolkit';

const modalSlice = createSlice({
  name: 'modal',
  initialState: {
    locationModal: false,
  },  
  reducers: {
    openLocationModal: (state) => {
      state.locationModal = true;
    },
    closeLocationModal: (state) => {
      state.locationModal = false;
    },
  },
});

export const { openLocationModal, closeLocationModal } = modalSlice.actions;
export default modalSlice.reducer;
