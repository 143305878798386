import React from "react";
import styles from "./MainLoading.module.css";

export default function MainLoading() {
  return (
    <div className={styles.container}>
      <div className={styles.header}></div>
      <div>
        {[...Array(5)].map((_, index) => (
          <div key={index} className={styles.listing_card}>
            <div className={styles.card_thumbnail}></div>
            <div className={styles.card_content}>
              <div className={styles.card_title}></div>
              <div className={styles.card_description}></div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
